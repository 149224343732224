// * * * UPDATED FOOTER DATE * * *
document.getElementById("year").textContent = new Date().getFullYear();

// * * * MAKE POST CARDS ALL CLICKABLE * * *
const cards = document.querySelectorAll(".home-work--grid-item");

cards.forEach((card) => {
  const mainLink = card.querySelector(".main-link");
  const clickableElements = Array.from(card.querySelectorAll("a"));
  // Stop propagation on any clickable elements within the card
  clickableElements.forEach((ele) =>
    ele.addEventListener("click", (e) => e.stopPropagation())
  );

  function handleClick(event) {
    const noTextSelected = !window.getSelection().toString();

    if (noTextSelected) {
      window.location.href = mainLink.href; // Navigate directly to the main link's href
    }
  }

  // Attach click event to the entire card
  card.addEventListener("click", handleClick);
});

// * * * GA TRACKING EVENTS * * *
const links = [
  { selector: "#resume-link", label: "resume-looked" },
  { selector: "#email-link", label: "email-looked" },
  { selector: "#talk-link", label: "talk-looked" },
  { selector: "#linkedin-link", label: "linkedin-looked" },
];

document.addEventListener("DOMContentLoaded", function () {
  function addTrackingEvent(link) {
    const element = document.querySelector(link.selector);
    if (element) {
      // Check if the element exists
      element.addEventListener("click", function () {
        gtag("event", "click", {
          event_category: "Portfolio Links",
          event_label: link.label,
        });
      });
    }
  }

  links.forEach(addTrackingEvent);
});

// * * * WORD CAROUSEL * * *
const carouselTrack = document.querySelector(".carousel-track");

// Check if the carouselTrack element exists
if (carouselTrack) {
  const words = [...carouselTrack.children];

  // Duplicate content until it fills the screen at least twice
  function fillCarousel() {
    const containerWidth = document.querySelector(".home-carousel").offsetWidth;
    let trackWidth = carouselTrack.scrollWidth;

    while (trackWidth < containerWidth * 2) {
      words.forEach((word) => {
        carouselTrack.appendChild(word.cloneNode(true));
      });
      trackWidth = carouselTrack.scrollWidth;
    }

    // Set animation duration based on total width
    const totalWidth = carouselTrack.scrollWidth;
    const animationDuration = totalWidth / 100; // Adjust speed as needed
    carouselTrack.style.animationDuration = `${animationDuration}s`;
  }

  fillCarousel();
}

// * * * HOVER POST EFFECT * * *
const supportsHover = window.matchMedia(
  "(hover: hover) and (pointer: fine)"
).matches;

if (supportsHover) {
  const gridItems = document.querySelectorAll(".home-work--grid-item");
  const gridContainer = document.querySelector(".home-work");

  gridItems.forEach((item) => {
    item.addEventListener("mouseenter", () => {
      // Get the color from the data-color attribute
      const color = item.getAttribute("data-color");
      // Set the CSS variable for the grid's background color
      gridContainer.style.setProperty("--hover-bg-color", color);
    });

    // Reset the background color when hover ends
    item.addEventListener("mouseleave", () => {
      gridContainer.style.setProperty("--hover-bg-color", "white"); // Default back to white
    });
  });
}

// * * * CONTEXT CARD HOVER * * *
document.addEventListener("DOMContentLoaded", () => {
  const badges = document.querySelectorAll(".context-badge");
  const screenPadding = 16; // Padding on each side of the screen

  // Utility to reset tooltip styles
  function resetTooltipStyles(tooltip) {
    tooltip.style.display = "none";
    tooltip.style.transform = "translateX(0)";
    tooltip.style.width = "";
  }

  // Utility to position the tooltip
  function positionTooltip(badge, tooltip) {
    const rect = badge.getBoundingClientRect();
    const tooltipWidth = tooltip.offsetWidth;
    const availableWidth = window.innerWidth - screenPadding * 2;

    let translateX = 0;

    // Adjust width if tooltip overflows available space
    if (tooltipWidth > availableWidth) {
      tooltip.style.width = `${availableWidth}px`;
      translateX =
        -(rect.left - screenPadding) + (availableWidth - rect.width) / 2;
    } else {
      const overflowLeft = rect.left - screenPadding;
      const overflowRight =
        rect.left + tooltipWidth - (window.innerWidth - screenPadding);

      if (overflowLeft < 0) {
        translateX = -overflowLeft;
      } else if (overflowRight > 0) {
        translateX = -overflowRight;
      }
    }

    tooltip.style.transform = `translateX(${translateX}px)`;

    // Adjust vertical positioning
    const spaceAbove = rect.top;
    const spaceBelow = window.innerHeight - rect.bottom;

    if (
      spaceAbove < tooltip.offsetHeight + 10 &&
      spaceBelow > tooltip.offsetHeight + 10
    ) {
      tooltip.style.top = "calc(100% + 8px)";
      tooltip.style.bottom = "auto";
    } else {
      tooltip.style.bottom = "calc(100% + 8px)";
      tooltip.style.top = "auto";
    }
  }

  // Function to show the tooltip
  function showTooltip(badge, tooltip) {
    tooltip.style.display = "flex";
    positionTooltip(badge, tooltip);
    setTimeout(() => tooltip.classList.add("visible"), 0);
  }

  // Function to hide the tooltip
  function hideTooltip(tooltip) {
    tooltip.classList.remove("visible");
    setTimeout(() => resetTooltipStyles(tooltip), 300); // Match CSS animation duration
  }

  // Function to handle click/tap event for toggling tooltip
  function toggleTooltip(badge, tooltip) {
    const isVisible = tooltip.classList.contains("visible");

    // Close all other visible tooltips
    document
      .querySelectorAll(".context-card--hover.visible")
      .forEach((otherTooltip) => {
        hideTooltip(otherTooltip);
      });

    if (!isVisible) {
      showTooltip(badge, tooltip);
    } else {
      hideTooltip(tooltip);
    }
  }

  // Attach event listeners
  badges.forEach((badge) => {
    const tooltip = badge.querySelector(".context-card--hover");
    if (!tooltip) return;

    // Desktop: Show tooltip on hover
    badge.addEventListener("mouseenter", () => showTooltip(badge, tooltip));
    badge.addEventListener("mouseleave", () => hideTooltip(tooltip));

    // Mobile/Tablet: Toggle tooltip on tap
    badge.addEventListener("click", (e) => {
      e.stopPropagation(); // Prevent clicks from propagating
      toggleTooltip(badge, tooltip);
    });
  });

  // Close tooltips on outside click
  document.addEventListener("click", () => {
    document
      .querySelectorAll(".context-card--hover.visible")
      .forEach((tooltip) => {
        hideTooltip(tooltip);
      });
  });

  // Handle window resize for dynamic repositioning
  window.addEventListener("resize", () => {
    document
      .querySelectorAll(".context-card--hover.visible")
      .forEach((tooltip) => {
        const badge = tooltip.closest(".context-badge");
        if (badge) positionTooltip(badge, tooltip);
      });
  });
});

// * * * COLOR BG CHANGE IN EXPERIENCE HIGHLIGHTS * * *
// const section = document.querySelector(".will-change-pink");

// if (section) {
//   console.log("Section found:", section);

//   const observer = new IntersectionObserver(
//     (entries) => {
//       entries.forEach((entry) => {
//         if (entry.isIntersecting) {
//           entry.target.classList.add("light-pink-bg");
//         }
//       });
//     },
//     { threshold: 0.1 }
//   );

//   observer.observe(section);
// } else {
//   console.error("No element found with class 'will-change-pink'.");
// }

// * * * FADE IN SECTION EFFECT IN CASE STUDY PAGES * * *
const fadeInElements = document.querySelectorAll(".fade-in");

const observer = new IntersectionObserver(
  (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("visible"); // Add the visible class
        observer.unobserve(entry.target); // Stop observing once it's visible
      }
    });
  },
  { threshold: 0.1 }
);

// Observe each element
fadeInElements.forEach((element) => {
  observer.observe(element);
});
